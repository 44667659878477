.success-contianer {
  width: 100%;
  height: 100vh;
  background: #f0f4f8;
}

.success-card {
  max-width: 30rem;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  min-width: 20rem;
  padding: 2rem;
  /* max-width: 30rem; */
  -webkit-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  inset: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.success-text {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0.5rem;
}

.done-icon {
  background-color: green;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  color: white;
}
.success-desc {
  font-size: 1rem;
  font: bold;
  max-width: 30rem;
}

.success-btn {
  font-weight: 700;
  font-size: 1.5rem;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgb(84, 84, 231);
}
