/*============================================== */
/* FEATURE*/
/* ============================================= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  min-height: 100%;
}
.more-info {
  height: 100vh;
}
.portion-feature {
  width: 95vw;
  -webkit-box-shadow: 0px 1px 5px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 5px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 5px -4px rgba(0, 0, 0, 0.75);
  margin: 2rem auto;
  padding: 2rem 1rem;
  background: white;
}
.price-estimate p {
  font-family: light;
  max-width: 45rem;
}

/*============================================== */
/* HELP*/
/* ============================================= */

.info {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}

.info-container {
  text-align: center;
  width: 15rem;
  height: 13rem;
  background: white;
  -webkit-box-shadow: 0px 1px 5px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 5px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 5px -4px rgba(0, 0, 0, 0.75);
}

.info-container p {
  padding-top: 1.5rem;
}

.talk-to-us {
  color: white;
  background: rgb(97, 155, 97);
  padding: 0.375rem 1.2rem;
  border-radius: 7px;
  border: none;
}
