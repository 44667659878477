/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

html,
body {
  font-family: "Montserrat";
  min-height: 100%;
}
.loading {
  display: grid;
  height: 100vh;
  place-items: center;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 0.8s linear infinite; /* Set animation to paused initially */
}

.animate {
  animation-play-state: running;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-select {
  outline: none;
  border: none;
  border: 1px solid black;
}

.header-back {
  display: flex;
  gap: 0.5rem;
}

.icon {
  /* display: flex; */
  align-items: center;
  /* margin-top: 2rem; */
}

.main {
  height: fit-content;
  /* width: 95vw; */
  width: 100%;
  /* flex-direction: row; */
  display: flex;
  position: relative;
}

.shipment-form-input label {
  font-family: light;
  max-width: 15rem;
}

.sidebar-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.disable-btn {
  opacity: 0.4;
}

.show-sidebar {
  min-width: 250px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* margin-top: 3rem; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: white;
  padding: 3rem 0 0 2rem;
  -webkit-box-shadow: 9px 9px 4px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 9px 9px 4px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 9px 9px 4px -10px rgba(0, 0, 0, 0.75);
  transition: all 0.3s ease-out;
}

.hide-sidebar {
  display: none;
}

.show-sidebar h1 {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  cursor: pointer;
  padding-bottom: 0;
}

.sidebar-links p {
  margin-bottom: 0;
}

.sidebar-link {
  height: 3rem;
  padding: 0.75rem 0;
  transition: all 0.3s ease-in-out;
}

.sidebar-link:hover {
  background-color: rgb(248 250 252);
  padding-left: 1rem;
}

.portioned-logo {
  display: flex;
  gap: 0.5rem;
  /* margin-left: 1rem; */
}
.portioned-logo h1 {
  padding-top: 0.5rem;
}

.portioned-logo button {
  border: none;
  background: transparent;
}

.portioned-logo button img {
  width: 30px;
  border: none;
}

@media screen and (min-width: 800px) {
  .show-sidebar {
    display: none;
  }

  .hide-sidebar {
    display: block;
  }

  .hide-sidebar {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    background: white;
    padding-left: 2rem;
    -webkit-box-shadow: 10px -7px 4px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px -7px 4px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 10px -7px 4px -10px rgba(0, 0, 0, 0.75);
  }

  .hide-sidebar h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .portioned-logo button {
    display: none;
  }
  .closeBtn {
    display: none;
  }
}

/* ############################################### */
/* NAVBAR */
/* ############################################### */

.nav-button {
  cursor: pointer;
  color: white;
  margin-top: 0.4rem;
  background: #3b82f6 !important;
  border: transparent;
  border-radius: 0.25rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: 0.3s ease-in-out all;
  text-transform: capitalize;
  display: flex;
  gap: 0.1rem;
  align-items: center;
}

.dropdown {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  background: #dbeafe;
  box-shadow: var(--shadow-2);
  padding: 0.5rem;
  text-align: center;
  visibility: hidden;
  border-radius: var(--borderRadius);
}

.show-dropdown {
  visibility: visible;
}

.dropdown-btn {
  background: transparent;
  border-color: transparent;
  color: #3b82f6;
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
  cursor: pointer;
}
/* ############################################### */
/* SHIPMENT DeLIVERY OPTIONS */
/* ############################################### */
p a {
  text-decoration: none;
}

.shipment-header h1 {
  display: none;
}

.shipment-header {
  padding-left: 2rem;
  padding-right: 2rem;
}

.shipment-header a {
  text-decoration: none;
  color: #000000;
  font-weight: 800;
  font-size: 20px;
  line-height: 29px;
}

.shipment-header h2 {
  font-size: 20px;
  margin-bottom: 0;
}

.shipment-page {
  background-size: cover;
  width: 100%;
  height: calc(100vh + 10rem);
  background: #f0f4f8;
  /* background-image: url("./pages/Assets/consignmentdetails.svg"); */
}
.page-content {
  width: 90vw;
  margin: 0 auto;
}

.shipment-header {
  display: flex;
  height: 5rem;
  align-items: end;
  justify-content: space-between;
  font-weight: 600;
  font-size: 32px;
  padding-bottom: 1rem;
  background: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  /* padding: 1rem 0.5rem; */
}

.shipment-info {
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.shipment-info-left {
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  height: calc(100vh - 5rem);

  padding: 2rem 1rem;
}

.shipment-link {
  font-size: 16px;
}

.shipment-info-right {
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  padding-top: 2rem;
  padding-right: 0.5rem;
}

.shipment-info-right h1 {
  font-weight: 600;
  font-size: 1.5rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.shipment-info-right p {
  font-family: light;
  max-width: 15rem;
}

.shipment-form-input {
  display: flex;
  flex-direction: column;
  max-width: 17rem;
  gap: 1rem;
}

.shipment-input {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
}

textarea[type="text"],
input[type="text"],
input[type="file"],
input[type="email"],
input[type="date"],
input[type="number"] {
  padding: 0.4rem;
  background: white;
}

.submit-btn {
  background: #0073cf;
  border: none;
  border-radius: 5px;
  text-align: center;
  color: white;
  height: 48px;
  width: 153px;
}
.active-option {
  color: #0073cf;
  cursor: pointer;
}

@media screen and (min-width: 460px) {
  .shipment-header h1 {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  .shipment-header img {
    display: none;
  }
  .shipment-page {
    width: 100%;
    height: calc(100vh + 6rem);
    background: #f0f4f8;
    background-size: cover;
  }

  .shipment-form-input {
    max-width: 20rem;
  }
}

@media screen and (min-width: 678px) {
  .shipment-info-right {
    margin-left: 8rem;
  }
}

@media screen and (min-width: 768px) {
  .shipment-info-left {
    padding-top: 4rem;
    padding-left: 2rem;
  }

  .shipment-info-right {
    margin-left: 10rem;
  }

  .shipment-info-left p {
    font-size: 18px;
  }

  .shipment-info-right h1 {
    font-size: 2rem;
  }

  .shipment-info-right p {
    max-width: 30rem;
  }

  .shipment-form-input label {
    max-width: 30rem;
  }

  .shipment-form-input {
    max-width: 27rem;
  }
}

/* ############################################### */
/* SHIPMENT CONSIGNMENT DETAILS */
/* ############################################### */
.consignment-page {
  /* height: fit-conte; */
  /* background-size: cover; */
  background: #f0f4f8;
}

.consignment-info {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 2rem;
}
.consignmentdetails-info-left {
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.5);

  padding: 2rem 1rem;
}

.consignmentdetails-info-right {
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  padding-top: 2rem;
  padding-right: 0.5rem;
  padding-bottom: 2rem;
}
.consignmentdetails-info-right h1 {
  font-weight: 600;
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

.consignmentdetails-info-right p {
  font-family: light;
  max-width: 15rem;
  margin-bottom: 0px;
}

.delivery-option-text {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #bb0000;
}

@media screen and (min-width: 460px) {
  .consignment-page {
    width: 100%;
    background: #f0f4f8;
  }
}
@media screen and (min-width: 678px) {
  .consignmentdetails-info-right {
    margin-left: 10rem;
  }
}

@media screen and (min-width: 768px) {
  .consignmentdetails-info-left {
    padding-top: 4rem;
    padding-left: 2rem;
  }

  .consignmentdetails-info-right p {
    font-family: light;
    max-width: 30rem;
    margin-bottom: 0px;
  }

  .consignmentdetails-info-right {
    margin-left: 10rem;
  }
}

/* ############################################### */
/* SHIPMENT CONSIGNMENT DOCUMENTS */
/* ############################################### */

.upload-input {
  background: transparent;
  border: none;
  outline: transparent;
  display: block;
  cursor: pointer;
  max-width: 15rem !important;
}

.consignmentdocuments-info-left {
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  padding: 2rem 1rem;
}

@media screen and (min-width: 460px) {
  .upload-input {
    max-width: 30rem !important;
  }
}

@media screen and (min-width: 768px) {
  .upload-input {
    max-width: 30rem;
  }
  .consignmentdocuments-info-left {
    padding-top: 4rem;
    padding-left: 2rem;
  }

  .consignmentdetails-info-right h1 {
    font-size: 2rem;
  }

  .consignmentdocuments-info-left p {
    font-size: 18px;
    max-width: 45rem;
  }
}
/* ############################################### */
/* SHIPMENT SUMMARY */
/* ############################################### */
.summary-page {
  width: 100%;
  background: #f0f4f8;
}

.summary-info-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 2rem 1rem;
}
.summary-info {
  display: flex;
  justify-content: center;
  /* justify-content: space-between;
  width: 100%;
  height: calc(100vh - 5rem); */
}

.summary-review-container {
  max-width: 45rem;
  /* display: flex;
  flex-direction: column; */

  /* gap: 6rem; */
}

.image-icons {
  background-color: green;
  border-radius: 50%;
  color: white;
}
.order-summary {
  display: flex;
  justify-content: space-between;
}

.summary-info-details,
.summary-info-option {
  width: 100%;
  background: white;
  margin-bottom: 0.5rem;
  padding: 1rem 2rem 1rem 0.5rem;
  border-radius: 10px;
  -webkit-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
}

.summary-info-details h2 {
  font-weight: 400;
  line-height: 20px;
}

.summary-info-details p,
.summary-info-option p {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0.5rem !important;
  font-family: light;
}

.summary-info-top {
  margin-top: 2rem;
  padding: 0 1rem;
}
.summary-info-bottom {
  margin-top: 3rem;
  border-top: 1px solid #000000;
}

.summary-text {
  padding-left: 1rem;
  padding-top: 1rem;
}

.summary-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: light;
}

.summary-text a {
  display: block;
  margin-top: 1.5rem;
  font-size: 14px;
  max-width: 15rem;
}

.summary-btn {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 0.75rem 1.5rem;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  /* width: 259px; */
  margin-top: 1rem;
}

.completeBtndiv {
  text-align: center;
  margin: 1rem 2rem;
}

.completeBtn {
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  text-align: center;
  color: white;
  padding: 0.5rem 1rem;
}
@media screen and (min-width: 460px) {
  .summary-page {
    background: #f0f4f8;
  }

  .summary-review-container {
    /* flex-direction: row; */
    width: 70vw;
    /* gap: 4rem; */
  }

  .summary-info-details p {
    font-size: 1rem;
    font-family: light;
  }

  .summary-info-right {
    max-width: 40rem;
    padding-right: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .summary-info-top h1 {
    font-size: 2rem;
  }

  .consignmentdocuments-info-top p {
    font-size: 18px;
    margin-bottom: 0 !important;
  }

  .summary-info-right {
    /* flex: 3;
  flex-direction: column;
  margin-top: 2rem; */
    max-width: 100rem;
    padding-right: 1rem;
  }
  .summary-review-container {
    flex-direction: row;
    /* width: 70vw; */
    gap: 4rem;
  }
}
/* ############################################### */
/* DASHBOARD INDEX*/
/* ############################################### */
.dashboard {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard-main {
  height: fit-content;
  display: flex;
}
.dashboard-right {
  flex: 6;
  background: #f0f4f8;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  /* background: url("./pages/Assets/consignmentdetails.svg");
  flex: 6;
  height: 100vh; */
}

.right-content-header {
  padding-top: 3rem;
  background: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  -webkit-box-shadow: 9px 9px 4px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 9px 9px 4px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 9px 9px 4px -11px rgba(0, 0, 0, 0.75);
}

.right-content-header div {
  /* display: flex;
  margin: 0 auto; */
}

.right-content-header button {
  background: transparent;
  border: none;
}

.right-content-header h6,
.right-content-header small {
  padding-left: 3rem;
  padding-bottom: 0.5rem;
}

.right-content-header div {
  margin: 0;
}
.dashboard-options {
  margin-top: 2rem;
  padding-bottom: 5rem;
  margin-bottom: 4rem;
  height: 100vh;
}

.price-rating {
  display: flex;
  gap: 4rem;
}
.dashboard-option {
  /* for larger device */
  margin: 1rem auto;
  width: 18rem;
  height: 20rem;
  background: #ffffff;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
}

.dashboard-nav {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  height: 3rem;
  /* padding-left: 0.4rem; */
}

.dashboard-nav h6 {
  cursor: pointer;
}

.dashboard-nav small {
  font-family: light;
}
.dashboard-add-shipment {
  text-align: center;
  margin-top: 6rem;
}

.dashboard-add-shipment small {
  display: flex;
  flex-direction: column;
  font-family: light;
  width: 253px;
  margin: 0 auto;
}

.dashboard-tracker {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  width: 258px;
  margin: 4rem auto 0 auto;
}

.dashboard-tracker small {
  font-family: light;
}

.dashboard-tracker input {
  width: 240px;
  margin: 0.7rem;
  outline: none;
  border-radius: 5px;
}

.dashboard-tracker button {
  width: 89px;
  height: 25px;
  background: #0073cf;
  border-radius: 5px;
  margin: 0 auto;
  color: white;
}

.dashboard-tracker input[type="text"] {
  padding: 0.5rem;
}

.dashboard-lcl-rate p {
  font-family: light;
  font-size: 12px;
  margin-bottom: 6px;
}

.dashboard-lcl-rate {
  /* text-align: left; */
  margin: 2rem;
}

.dashboard-select-btn {
  display: flex;
  justify-content: space-between;
}

.dashboard-select-btn button:first-child {
  width: 104px;
  height: 27px;
  background: #0073cf;
  border-radius: 5px;
  color: white;
  outline: none;
}

.dashboard-select-btn button:nth-child(2) {
  background: transparent;
  outline: none;
  border: none;
  font-family: light;
}

@media screen and (min-width: 460px) {
}
@media screen and (min-width: 640px) {
  .dashboard-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 1rem;
    width: 100%;
  }
}

@media screen and (min-width: 800px) {
  .right-content-header {
    justify-content: right;
  }
  .menu-logo {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .dashboard-option {
    width: 24rem;
    height: 20rem;
  }
}

/*  */
/* ############################################### */
/* DASHBOARD  TRACKING*/
/* ############################################### */
.tracking {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
/* .dashboard {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
} */

.tracking-link {
  padding: 1rem 0;
}

.tracking-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding-left: 2rem;
}

.tracking-links {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.tracking-left h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #000000;
}
.tracking-right {
  flex: 6;
  background: #f0f4f8;
  height: 100vh;
  /* margin-top: 1.5rem; */
  width: 100%;
}
.tracking-right-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tracking-header {
  display: flex;
  justify-content: space-between;
  background: white;
  height: 112px;
  margin: 2rem 3rem;
  padding: 0.5rem 2rem;
  border: 1px solid #000000;
  border-radius: 5px;
}
.header-option {
  height: 5rem;
}
.tracking-footer {
  background: white;
  border: 1px solid #000000;
  border-radius: 5px;
  margin: 0 1.5rem;
}
.footer-header {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid black;
  align-items: center;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 1.5rem;
}

@media screen and (min-width: 800px) {
  .tracking-main {
    display: flex;
  }
}

/* ############################################### */
/* DASHBOARD  HISTORY*/
/* ############################################### */
.history-link {
  padding: 1rem 0;
}

.history-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding-left: 2rem;
}

.history-left h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #000000;
}

.history-links {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.history-details {
  background: white;
  padding: 0.5rem;
  -webkit-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  margin-bottom: 1rem;
  border-radius: 10px;
}

.history-right-content {
  padding: 2rem 1rem 1rem 1rem;
}

.history-right {
  flex: 6;
  /* height: 100vh; */
  background: #f0f4f8;
}

.full-screen {
  height: 100vh;
}

.history-delete {
  background: red;
  color: white;
  border-radius: 10px;
  padding: 0.3rem 0.75rem;
  margin-top: 1rem;
  border: none;
}

.search-history {
  display: block;
  background: transparent;
  border-bottom: 1px solid black;
  width: 250px;
}

.search-history input[type="text"] {
  padding: 0.2rem;
  font-family: light;
}

.search-history input {
  border: transparent;
  outline: transparent;
}

.search-history input[type="text"] {
  background: transparent;
}

.history-menu {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  gap: 1rem;
}

.history-menu button {
  border: none;
}

.history-menu h6 {
  margin-bottom: 0;
}

.deliver-btn {
  width: 86px;
  height: 15px;
  background: #008000;
  border: 1px solid #000000;
  border-radius: 10px;
  font-size: 8px;
  color: white;
}

.transit-btn {
  width: 86px;
  height: 15px;
  background: #01649d;
  border: 1px solid #000000;
  border-radius: 10px;
  font-size: 8px;
  color: white;
}
.pending-payment {
  width: 86px;
  height: 15px;
  background: #ffcc00;
  border: 1px solid #000000;
  border-radius: 10px;
  font-size: 8px;
  color: white;
}

.history-info {
  display: flex;
  gap: 2rem;
  margin-top: 1.5rem;
}
/* .delivery-info {
  display: flex;
  align-items: center;
  gap: 2rem;
} */

.delivery-info {
  display: flex;
  flex-direction: column;
}

.history-info p {
  margin-bottom: 0;
}

.delivery-destination small,
.delivery-date small {
  font-family: light;
}

.delivery-date,
.delivery-destination {
  display: flex;
  gap: 3rem;
  /* flex-direction: column; */
}

@media screen and (min-width: 460px) {
  .history-right-content {
    margin-left: 3rem;
    height: 100vh;
    overflow: scroll;
    scroll-behavior: smooth;
  }

  .deliver-btn,
  .transit-btn,
  .pending-payment {
    font-size: 12px;
    width: 166px;
    height: 22px;
  }
}

@media screen and (min-width: 800px) {
  .history-menu h6 {
    font-size: 20px;
    margin-top: 0.75rem;
  }

  .history-menu button {
    display: none;
  }
}
/* ############################################### */
/* DASHBOARD  PROFILE*/
/* ############################################### */
.profile-right {
  flex: 6;
  height: 100%;
  background: #f0f4f8;
  /* background-image: url("./pages/Assets/consignmentdetails.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
}
.header-nav h6 {
  display: flex;
  align-items: center;
}
.header-nav button {
  border: none;
  background: transparent;
}
.header-nav button img,
.dashboard-nav img {
  width: 25px;
}
.header-nav {
  display: flex;
  justify-content: center;
}
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 5rem;
  border-bottom: 1px solid black;
  margin: 0 1.5rem;
  padding-bottom: 0.5rem;
}
.profile-header a {
  font-family: light;
  font-style: italic;
  border: none;
  background: transparent;
}

.profile-options {
  display: grid;
  place-items: center;
  margin-top: 2rem;
  height: 100%;
  width: 100%;
}

.profile-options-heading {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  gap: 0.5rem;
  cursor: pointer;
}

.profile-options-content p {
  font-family: light;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.profile-options-container {
  background: white;
  margin-bottom: 1rem;
  width: 18rem;
  height: 18rem;
  padding: 1rem 1rem 3rem 1rem;
  -webkit-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  /* padding-bottom: 2rem; */
  border-radius: 10px;
}
.profile-options-content {
  padding-left: 2rem;
  max-width: 20rem;
  font-size: 14px;
}

@media screen and (min-width: 800px) {
  .profile-options-container {
    width: 15rem;
  }
  .header-nav button img {
    display: none;
  }
  .profile-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 1rem;
    width: 80vw;
    height: fit-content;
    margin: 2.5rem auto 0.5rem auto;
  }
}

@media screen and (min-width: 992px) {
  .profile-options-container {
    width: 18rem;
  }
}

@media screen and (min-width: 1117px) {
  .profile-options-container {
    width: 18rem;
  }
  .profile-options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 1.5rem;
    width: 80vw;
    height: fit-content;
    margin: 2.5rem auto 0.5rem auto;
  }

  .profile-options-content {
    padding-left: 2rem;
    max-width: 15rem;
  }
}

/* ############################################### */
/* DASHBOARD  EDIT*/
/* ############################################### */
.edit-right {
  /* background: url("./pages/Assets/consignmentdetails.svg"); */
  flex: 6;
  background: #f0f4f8;
  height: fit-content;
  width: 100%;
}

.edit-nav {
  border-bottom: 1px solid black;
}

.edit-right-content {
  padding-right: 0.5rem;
  padding-left: 1rem;
}
.edit-nav button {
  background: transparent;
  border: none;
}
.edit-form {
  padding-left: 3rem;
  margin-top: 3rem;
  height: 100vh;
}
.edit-footer {
  display: flex;
  /* gap: 10rem; */
  max-width: 60%;
  max-width: 25rem;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.edit-footer p {
  font-family: light;
}

.edit-footer > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

#prefered-language,
#delete,
#logout,
#english {
  margin-bottom: 0 !important;
  padding: 0.5rem 0;
}

.delete-account,
.log-out {
  display: flex;
  max-width: 25rem;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.right-arrow-container p,
.delete-account p,
.log-out p {
  display: flex;
  align-items: center;
  font-family: light;
}
/* ############################################### */
/* DASHBOARD  PAYMENT*/
/* ############################################### */
.payment-right {
  flex: 6;
  background: #f0f4f8;
}

.payment-right-content {
  margin-top: 3rem;
  height: 100vh;
}
.payment-right-content-header {
  padding-left: 3rem;
  border-bottom: 1px solid black;
  padding-bottom: 1rem;
  display: flex;
}

/* .payment-right-content-header div {
  display: flex;
  align-items: center;
  gap: 1em;
} */

/* .payment-right-content-header div button {
  border: none;
  background: transparent;
} */

/* .payment-list {
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.payment-billing-history {
  padding-left: 2rem;
  padding-right: 0.7rem;
  margin: 3rem 0.5rem 2rem 1rem;
  background: white;
  padding-top: 2rem;
  border: 1px solid transparent;
  border-radius: 5px;
  height: 20rem;
  -webkit-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.75);
} */

@media screen and (min-width: 678px) {
  .payment-list {
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }
}

@media screen and (min-width: 768px) {
  .payment-right-content-header div button img,
  .payment-right-content-header div button {
    display: none;
  }
}
/* ############################################### */
/* ADD PAYMENT MODAL*/
/* ############################################### */
.payment-method {
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
}

.add-payment {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  min-width: 20rem;
  padding: 2rem;
  /* max-width: 30rem; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.payment-form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.card-pin-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}
.card-pin,
.card-cvv {
  display: flex;
  flex-direction: column;
}

.btn-container {
  display: flex;
  gap: 1rem;
}

.payment-btn {
  display: block;
  width: 100%;
  background: #0073cf;
  border-radius: 5px;
  color: white;
  padding: 0.5rem 0;
  outline: none;
  outline: none;
  border: none;
}
.cancel-btn {
  background: red;
}

.visa {
  width: 100% !important;
  height: 100% !important;
  border-radius: 5px;
  display: block;
  object-fit: cover;
}

@media screen and (min-width: 1130px) {
  .card-pin-details {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .card-pin-details {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
}
/* 
@media screen and (min-width: 768px) {
  .add-payment {
    margin-top: 10rem;
  }
} */

.add-visa {
  width: 18rem;
  height: 13rem;
  margin-top: 2rem;
  object-fit: cover;
}

.card-validity {
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
}

.card-validity p {
  font-family: light;
  color: red;
  font-style: italic;
  font-size: 10px;
}

.remove-payment-method {
  text-decoration: underline;
  cursor: pointer;
}
