.table-wrapper {
  max-width: min(1128px, 100% - 3rem);
  margin-inline: auto;
  -webkit-box-shadow: 0px 1px 5px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 5px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 5px -3px rgba(0, 0, 0, 0.75);
}

table {
  padding: 1rem;
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  font-size: 12px;
  background: white;
  padding: 1rem;
}

.table-container {
  overflow-x: auto;
  /* max-width: 100%; */
}
.table-header {
  border-bottom: 1px solid black;
  padding-top: 1rem;
}

tr:hover {
  background-color: whitesmoke;
}

@media screen and (min-width: 678px) {
  th {
    font-size: 16px;
  }
  td {
    font-size: 14px;
  }
}
