@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: light;
  src: url("../fonts/montserrat-light.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat;
}
small {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
}

.light {
  font-family: light;
}

li {
  list-style: none;
}

.mainPadding {
  padding-left: 143px;
  padding-right: 143px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  margin-left: 2rem;
}

.nav-link {
  font-size: 16px;
  font-weight: 400;
  color: #475569;
}

.nav-link:hover {
  color: #482ff7;
}

.nav-logo {
  font-size: 24px;
  font-weight: 500;
  color: #482ff7;
}

.btn-padding {
  border-radius: 5px;
}
.aboutTitle {
  font-size: 48px;
}
.aboutMain {
  border-radius: 10px;
}
.resourceTitle {
  font-size: 28px;
  font-weight: 700;
}
.bolder {
  font-weight: bolder;
  font-size: 36px;
}
.resourceContent {
  font-size: 24px;
  font-weight: 400;
}
.bgLight {
  background-color: #f5f5f5;
}
.joiedpor {
  background-image: url("../images/joinedportion.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 900px) {
  .nav-menu {
    display: block;
    position: fixed;
    left: -100%;
    top: 5rem;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  .btnMargin {
    padding-bottom: 20px;
  }

  .nav-menu.active {
    left: 0%;
  }

  .nav-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}

.main-title {
  font-size: 80px;
  font-weight: 700;
  line-height: 100px;
}

.main-content {
  font-size: 35px;
  font-weight: 400;
  width: 80%;
  margin: auto;
  padding-top: 15px;
  font-family: light;
}

.main-section {
  padding-top: 20px;
  padding-bottom: 55px;
  height: fit-content;
}

.blue-border {
  border: 2px solid #0073cf;
}

.main-form-title {
  font-size: 32px;
  font-weight: 700;
}

.customInput::placeholder {
  font-size: 14px;
}

.mainForm {
  width: 80%;
}

.borderForm {
  border-color: #0073cf !important;
}

.wfc {
  width: fit-content;
}

.chooseTitle {
  font-size: 48px;
  font-weight: 700;
}

.chooseContent {
  font-size: 32px;
  font-weight: 500;
}

.chooseCont {
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  font-family: light;
}

.mainSteps {
  font-size: 30px;
}

.primarySection {
  border-radius: 5px;
}

.margin {
  margin-top: 60px;
}

.imgMargn {
  margin: auto;
}

.accordion-button {
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: light;
}

.shippingTitle {
  font-size: 48px;
  font-weight: 400;
}

.shippingBtn {
  padding: 20px 45px;
  border-radius: 5px;
}

.ComingTitle {
  font-size: 48px;
  font-weight: 700;
}

.growTitle {
  font-size: 32px;
  font-weight: 700;
}

.getTitle {
  font-size: 24px;
  font-weight: 700;
}

.comingBtn {
  border-radius: 5px;
}

/***************************
               FOOTER
****************************/
footer {
  background-color: var(--primary);
  color: var(--lite);
}

footer h3 {
  color: var(--white);
  margin-bottom: 1.5rem;
}

footer a {
  color: var(--lite);
  display: block;
  margin: 15px 0;
}

footer a:hover {
  color: var(--white);
}

footer fieldset {
  padding: 0;
}

footer fieldset input {
  background-color: white;
  border: 0;
  color: var(--lite);
  padding: 9px;
  width: 70%;
}

footer fieldset .btn {
  border-radius: 0;
  border: 0;
}

footer fieldset .btn_2:hover {
  background-color: var(--secondary);
  border: 0;
  color: var(--primary);
}

footer .flex:last-child {
  align-items: center;
}

footer .flex:last-child .flex-content:last-child {
  text-align: right;
}

footer .flex:last-child p {
  color: var(--white);
}

footer .flex:last-child a {
  width: 40px;
  display: inline-block;
  background-color: #334f6c;
  color: var(--white);
  padding: 0.5rem;
  margin-right: 3px;
  text-align: center;
}

footer .flex:last-child a:hover {
  background-color: var(--secondary);
  color: var(--gray);
}

.text-red {
  color: #bb0000;
}

@media (max-width: 1100px) {
  footer .flex:first-child {
    flex-wrap: wrap;
  }

  footer .flex:first-child .flex-content {
    flex: 1 1 40%;
  }
}

@media (max-width: 920px) {
  footer .flex:last-child .flex-content:last-child {
    text-align: left;
  }
}

@media (max-width: 320px) {
  footer .flex:first-child .flex-content {
    flex: 1 1 100%;
  }
}

@media only screen and (max-width: 786px) {
  .mainPadding {
    padding: 0 15px !important;
  }

  .nav-menu {
    padding-left: 0px !important;
  }

  .main-title {
    font-size: 35px;
    line-height: 40px;
  }

  .main-section {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .main-content {
    font-size: 20px;
    width: 100%;
  }

  .LoginSection {
    flex-direction: column-reverse;
  }

  .loginTitle {
    font-size: 28px;
  }

  .loginForm {
    width: 100%;
  }

  .wfc {
    margin: 0;
  }

  .imgWidth {
    width: 100%;
  }

  .chooseTitle {
    font-size: 30px;
  }

  .chooseContent {
    font-size: 20px;
  }

  .chooseCont {
    font-size: 18px;
  }

  .shippingTitle {
    font-size: 32px;
  }

  .accordion-button {
    font-size: 16px !important;
  }

  footer fieldset input {
    width: 60%;
  }

  .ComingTitle {
    font-size: 32px;
  }

  .growTitle {
    font-size: 26px;
  }

  .getTitle {
    font-size: 22px;
  }

  .mainForm {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .mainForm {
    width: 100%;
  }

  .LoginSection {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .main-title {
    font-size: 62px;
    line-height: 75px;
  }

  .main-content {
    font-size: 25px;
    font-weight: 400;
    width: 100%;
  }

  .chooseTitle {
    font-size: 40px;
  }

  .chooseContent {
    font-size: 25px;
  }

  .primarySection {
    padding: 2rem !important;
  }

  .mainPadding {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media only screen and (min-width: 994px) and (max-width: 1200px) {
  .partitioned {
    display: flex !important;
    flex-wrap: inherit !important;
  }
}
